import { observer } from 'mobx-react-lite';
import React from 'react'
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../..';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import UserServices from '../../services/general/userServices';
import Utils from '../../services/utils';
import OrderDetailCard from '../simple/OrderDetailCard';
import { toast } from 'react-toastify';
import img from "../../images/placeholder.png"
import qrcode from "../../images/image 7.png"
import OrderDetailLoading from '../simple/OrderDetailLoading';

const OrderProductDetail = observer(() => {
    const {id} = useParams();

    const {app, user} = useContext(Context);

    const {info, loading} = useFetchInfo(UserServices.orders.view, id);

    const navigate = useNavigate();

    const repeatOrder = async () => {
        const res = await UserServices.cart.repeatOrder(info.id);

        if(res.statusCode === 200){
            toast.success(app.localizationsItems?.product_to_cart_success);
            user.setBasketChange(Math.random().toString(16))
        }else{
            toast.error(res.message)
        }
    }

  return (
    <div className='profile-dashboard'>
        {loading ?
            <OrderDetailLoading/>
            :
            info &&
                <>
                    <div className='flex button-back'>
                        <i className='icon-chevron-left' onClick={() => navigate(`/${app.lang}/mob-user`)}></i>
                        <h3>{app.localizationsItems?.order} №{info.number}</h3>
                        {info.status && <div className='order-card-state' style={{background: info.status.color}}>{info.status.name}</div>}
                    </div>
                    <div className="order-card">
                        <h5>{app.localizationsItems?.delivery_payment}</h5>
                        <ul className='order-list'>
                            <li>{app.localizationsItems?.order_date}: <span>{info.created_at && Utils.generateOrderDate(info.created_at)}</span></li>
                            <li>{app.localizationsItems?.delivery_variant}: <span>{info.delivery?.name}</span></li>
                            <li>{app.localizationsItems?.address}: <span>{info.address?.address ? info.address?.address : info.address?.full_address}</span></li>
                            <li>{app.localizationsItems?.payment_variant}: <span>{info.payment.name}</span></li>
                            <li>{app.localizationsItems?.message}: <span>{info.description}</span></li>
                        </ul>
                    </div>
                    {/*<div className='qr-code'>
                        <h5>{app.localizationsItems?.qr_code}</h5>
                        <img src={qrcode} alt="" />
                    </div>*/}
                    <div className="order-card-middle">
                        <h5>{app.localizationsItems?.order_list}</h5>
                        <div>
                            {info.products?.map((card, index) =>
                                <OrderDetailCard card={card} key={index}/>
                            )}

                            {Array.isArray(info?.promo_info) && info.promo_info?.map((promo, index) =>
                                promo?.additional_products?.map((pr, ind) =>
                                    <div className='order-detail-card' key={ind}>
                                        <div className='order-detail-flex'>
                                            <img src={pr.preview_image ? pr.preview_image : img} alt={pr.title} title={pr.title}
                                                width={107}
                                                height={107}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; 
                                                    currentTarget.src = img
                                                }}
                                            />
                                            <div className='order-detail-content'>
                                                <h5>{pr.title}</h5>
                                                <div className='price'>
                                                    <span className='title'>{app.localizationsItems?.promo_product_content}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                    <div className='order-card'>
                        <h5 className='flex'>{app.localizationsItems?.sub_total} <span>{Utils.numberWithSpaces(info.sub_total_price)} {app.localizationsItems?.currency}</span></h5>
                        {info?.delivery_price ? 
                            <div className='flex'>{app.localizationsItems?.delivery_price} <span>{info.delivery_price} ₸</span></div> :
                            info?.promo_info?.type?.slug === "delivery" ? 
                            <div className='flex active-text'>{app.localizationsItems?.promo_delivery_content} <span>{app.localizationsItems?.free}</span></div> :
                            <div className='flex'>{app.localizationsItems?.delivery_price} <span>{info.delivery_price} ₸</span></div>
                        }
                        {info?.bonus_used ? <div className='flex'>{app.localizationsItems?.bonus_gained} <span>{Utils.numberWithSpaces(info.bonus_used)} ₸</span></div> : <></>}
                        {info?.discount_price ? <div className='flex active-text'>{app.localizationsItems?.discount_price} <span>-{Utils.numberWithSpaces(info.discount_price)} ₸</span></div> : <></>}
                        {Array.isArray(info?.promo_info) && info?.promo_info?.map((promo, index) =>
                            promo.type?.slug === "min_price_discount" ? 
                                <div className='flex active-text' key={index}>
                                    {app.localizationsItems?.promo_discount_content} <span>-{Utils.numberWithSpaces(promo.discount)} ₸</span>
                                </div> 
                                : 
                                <></>
                            
                        )}
                        <h5 className='flex'>{app.localizationsItems?.total} <span>{Utils.numberWithSpaces(info.total_price)} {app.localizationsItems?.currency}</span></h5>
                        {info?.bonus_gained ? <div className='flex'>{app.localizationsItems?.bonuses} <span className='product-bonus'>{Utils.numberWithSpaces(info.bonus_gained)} ₸</span></div> : <></>}
                    </div>
                    <button className='main-btn accent-btn mt-3' onClick={repeatOrder}>{app.localizationsItems?.repeat_order}</button>
                </>
        }
    </div>
  )
})

export default OrderProductDetail